import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { Preloader } from 'nbds-react';

const NotFound = lazy(() => import('pages/not-found/NotFound'));
const PriceSearch = lazy(() => import('pages/price-search/PriceSearch'));
const SavingsCard = lazy(() => import('pages/savings-card/SavingsCard'));

function AppRoutes() {
  return (
    <main className="app-main" role="main">
      <Suspense fallback={<Preloader message="Loading Content..." />}>
        <Routes>
          <Route path="/price-search" element={<PriceSearch />} />
          <Route path="/savings-card" element={<SavingsCard />} />
          <Route path="/" element={<PriceSearch />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </main>
  );
}

export default AppRoutes;
