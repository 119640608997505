import React from 'react';

import AppFooter from 'components/app-footer/AppFooter';
import AppHeader from 'components/app-header/AppHeader';
import AppRoutes from 'components/app-routes/AppRoutes';

import './App.scss';

function App() {
  return (
    <>
      <AppHeader />
      <AppRoutes />
      <AppFooter />
    </>
  );
}

export default App;
