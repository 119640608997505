export const disclaimer = `
  <p><strong>Pharmacy discounts are not insurance, not intended as a substitute for insurance, and only available at participating pharmacies.</strong></p>
`;

export const terms = `
  <p>By using the New Benefits Rx pharmacy discount (the “Product”), you confirm that you understand and agree to comply with the terms and conditions set forth below.</p>
  <ol class="nbds-list">
    <li>The Product is not insurance and cannot be combined with any insurance benefit, copay assistance program, or other third-party financial benefits. When you use the Product, you are responsible for the discounted cost of your prescription(s), and you agree not to seek reimbursement for any cost incurred using this Product from any commercial or employer sponsored insurance, or any federal or state funded health care program (for instance by submitting a manual claim). You may be able to use a Flexible Savings Account (“FSA”) to pay for your prescription when using the Product, depending on the terms of your FSA. New Benefits Rx does not make payments to providers.</li>
    <li>While New Benefits Rx attempts to capture and provide accurate pricing and other information, we cannot guarantee that the price you pay at the participating pharmacy will always be the price that is displayed or advertised in advance of your purchase. Pricing may change over time. The purchase price is based on the medication you order or pick up at the pharmacy, and the actual discount will vary depending on the medication. If you order or pick up a different form, dosage, or quantity, you may pay a different amount.</li>
    <li>The Product is not a substitute for a prescription. You must present or have a valid prescription to receive any prescription drugs with the Product.</li>
    <li>New Benefits Rx does not guarantee that you will be able to obtain any particular pharmaceutical product. The Product may not be available for certain products, such as DEA-controlled substances, fentanyl patches, transmucosal products, opium/opium tincture and methadone. Additionally, use of the Product is subject to certain supply quantity and/or dose limits for certain medications, including short-acting opioids, as well as other pharmaceutical products.</li>
    <li>By using the Product, you agree that New Benefits Rx may provide aggregated and de-identified information to third parties, including data aggregators and pharmaceutical manufacturers. This may result in revenue to New Benefits Rx.</li>
    <li>We reserve the right to rescind or revoke the Product at any time.</li>
    <li>The Product is not a recommendation to use any service or drug. We are not offering advice, or recommending or endorsing any specific prescription drug, pharmacy, or other service or product.</li>
    <li><span>Product is administered by New Benefits, Ltd., Dallas, TX. For questions or to locate participating pharmacies: <a class="nbds-font-600" href="tel:+18008007616">800-800-7616</a>.</span></li>
  </ol>
  <p>New Benefits, Ltd. is committed to providing a website that is accessible to the widest possible audience, regardless of technology or ability. We aim to comply with all applicable standards, including WCAG 2.0 accessibility standards up to level AA, and are continually seeking out solutions to improve overall web accessibility. If you experience any difficulty in accessing any part of this website, please feel free to call us at <a class="nbds-font-600" href="tel:+18008008304">800-800-8304</a> or to email us at <a class="nbds-font-600" href="mailto:info@newbenefits.com">info@newbenefits.com</a>.</p>
`;
