import React from 'react';

import { MatchMedia } from 'nbds-react';

import logo from 'assets/new-benefits-rx.svg';
import './AppHeader.scss';

function AppHeader() {
  return (
    <header className="app-header">
      <div className="app-header-content">
        <a href="/" id="app-header-logo" title="Home"><img src={logo} alt="New Benefits Rx" /></a>
        <MatchMedia query="(min-width: 48rem)">
          <p aria-hidden="true"><strong>Prescription savings made simple!</strong></p>
        </MatchMedia>
      </div>
    </header>
  );
}

export default AppHeader;
