/* eslint-disable react/no-danger */

import React, { useState } from 'react';

import {
  Footer,
  Modal,
  ModalContent,
  SecondaryButton,
} from 'nbds-react';

import logo from 'assets/new-benefits-rx-wt.svg';
import { disclaimer, terms } from './markup';
import './AppFooter.scss';

function AppFooter() {
  const [showTerms, setShowTerms] = useState(false);

  const copyright = (
    <p className="nbrx-copyright nbds-font-01">
      {`©2000-${new Date().getFullYear()} Copyright New Benefits, Ltd. All rights reserved.`}
      <SecondaryButton
        size="small"
        text="Terms of Use"
        onClick={() => setShowTerms(true)}
      />
    </p>
  );

  const legal = (
    <>
      <img src={logo} alt="New Benefits Rx" />
      <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
    </>
  );

  return (
    <>
      <Footer
        copyright={copyright}
        legal={legal}
      />
      <Modal
        ariaLabel="terms-of-use"
        showModal={showTerms}
        onClose={() => setShowTerms(false)}
      >
        <ModalContent mode="header">
          <h2>New Benefits Rx Terms of Use</h2>
        </ModalContent>
        <ModalContent mode="content">
          <div dangerouslySetInnerHTML={{ __html: terms }} />
        </ModalContent>
      </Modal>
    </>
  );
}

export default AppFooter;
